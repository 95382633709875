import { IBaseRequest, IBaseResponse } from '@/api/types';
import { SupportingDocumentsRequest } from '@/entities';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers.ts';
import { ICreateSupportingDocumentsRequestDto } from '@/api/methods/supportingDocumentsRequests/createSupportingDocumentsRequest.ts';

interface IUpdateSupportingDocumentsRequestProps extends IBaseRequest {
  clientId: string;
  id: string;
  dto: ICreateSupportingDocumentsRequestDto;
}

export async function updateSupportingDocumentsRequest({
  api,
  clientId,
  dto,
  id,
}: IUpdateSupportingDocumentsRequestProps): Promise<
  IBaseResponse<SupportingDocumentsRequest>
> {
  try {
    const response = await api.post<IBaseResponse<SupportingDocumentsRequest>>(
      `clients/${clientId}/supporting_documents_requests/${id}`,
      dto,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
