import { DraftPreview } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';

interface IGetDraftPreviewProps extends IBaseRequest {
  id: string;
  clientId: string;
  params?: {
    validateContact?: boolean;
    validateCore?: boolean;
    validateSupDoc?: boolean;
  };
}

export async function getDraftPreview({
  api,
  id,
  params,
  clientId,
}: IGetDraftPreviewProps): Promise<IBaseResponse<DraftPreview>> {
  const response = await api.get<IBaseResponse<DraftPreview>>(
    `clients/${clientId}/drafts/${id}/preview`,
    { params },
  );

  return response.data;
}
